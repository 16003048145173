import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import DistrictAssignHotelModal from "../components/DistrictAssignHotelModal";
import BusinessGuestChangeModal from "../components/BusinessGuestChangeModal";
import TripTable from "../tables/TripTable";
import {updateCurrentUrlParams} from "../../services/url";
import {useHistory, useLocation} from "react-router";


const ViewTrips = withRouter((props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tripID } = useParams()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    status: searchQuery.get('status') ?? '',
    orderBy: searchQuery.get('orderBy') ?? '',
    futureStartDate: searchQuery.get('futureStartDate') ?? '',
    countSegments: searchQuery.get('countSegments') ?? '',
    tripHasNullRates: searchQuery.get('tripHasNullRates') ?? '',
    hotelInvoiceDelta: searchQuery.get('hotelInvoiceDelta') ?? '',
  }

  useEffect(() => {
    dispatch(actions.getOffer())
    dispatch(actions.getSubscription())
    dispatch(actions.getHotel())
  },[dispatch])

  //const isLoading = useSelector(state => state.reservation.isLoading)
  const isLoading = false

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon"/>
          <div className="headlineIconText">Trips</div>
        </div>
        <div>Group customer reservations to trips</div>
        <div style={{marginLeft: 30, padding: 6, backgroundColor: '#f1f5ec'}}>
          <button style={query.status === ''
          && query.futureStartDate !== '1'
          && query.tripHasNullRates !== '?'
          && query.hotelInvoiceDelta !== 'x'
          && query.hotelInvoiceDelta !== '??'
          && query.hotelInvoiceDelta !== '!'
            ? {fontWeight: 'bold'} : {}}
                  onClick={() => updateCurrentUrlParams(history, {
                    status: '',
                    orderBy: '1',
                    orderDirection: 'asc',
                    futureStartDate: '',
                    tripHasNullRates: '',
                    hotelInvoiceDelta: '',
                  })}>all
          </button>
          <span style={{marginLeft: 10, fontWeight: 'bold', color: '#5b8328'}}>FILTER:</span>
          <span style={{marginLeft: 10}}>
            <button
              style={query.status === 'new|partially_confirmed' && query.orderBy === '1' ? {fontWeight: 'bold'} : {}}
              title="status = (new OR partially_confirmed) AND order by = arrival"
              onClick={() => updateCurrentUrlParams(history, {
                status: 'new|partially_confirmed',
                orderBy: '1',
                orderDirection: 'asc',
                futureStartDate: '1',
                tripHasNullRates: '',
                hotelInvoiceDelta: '',
              })}>pending confirmation</button>
            <button
              style={query.status === '' && query.orderBy === '1' && query.futureStartDate === '1' ? {fontWeight: 'bold'} : {}}
              title="futureStartDate === '1' AND order by = arrival"
              onClick={() => updateCurrentUrlParams(history, {
                status: '',
                orderBy: '1',
                orderDirection: 'asc',
                futureStartDate: '1',
                tripHasNullRates: '',
                hotelInvoiceDelta: '',
              })}>upcoming arrivals</button>
            <button
              style={query.status === '' && query.orderBy === '22' ? {fontWeight: 'bold'} : {}}
              title="order by = created"
              onClick={() => updateCurrentUrlParams(history, {
                status: '',
                orderBy: '22',
                orderDirection: 'desc',
                futureStartDate: '',
                tripHasNullRates: '',
                hotelInvoiceDelta: '',
              })}>last created</button>

          </span>
          <span style={{marginLeft: 10}}>Rates &nbsp;

            <button style={query.tripHasNullRates === '?' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {
                      status: '',
                      orderBy: '1',
                      orderDirection: 'asc',
                      futureStartDate: '',
                      tripHasNullRates: '?',
                      hotelInvoiceDelta: '',
                    })}>missing</button>
          </span>
          <span style={{marginLeft: 10}}>Invoices &nbsp;
            <button style={query.hotelInvoiceDelta === '??' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {
                      status: '',
                      orderBy: '1',
                      orderDirection: 'asc',
                      futureStartDate: '',
                      tripHasNullRates: '',
                      hotelInvoiceDelta: '??',
                    })}>missing</button>
            <button style={query.hotelInvoiceDelta === 'x' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {
                      status: '',
                      orderBy: '1',
                      orderDirection: 'asc',
                      futureStartDate: '',
                      tripHasNullRates: '',
                      hotelInvoiceDelta: 'x',
                    })}>delta</button>
            <button style={query.hotelInvoiceDelta === '!' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {
                      status: '',
                      orderBy: '1',
                      orderDirection: 'asc',
                      futureStartDate: '',
                      tripHasNullRates: '',
                      hotelInvoiceDelta: '!',
                    })}>soft ok</button>

          </span>
        </div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading/> : <TripTable tripID={tripID}/>}
        </Grid>
      </Grid>
      <DistrictAssignHotelModal/>
      <BusinessGuestChangeModal/>
    </div>
  )
})


export default ViewTrips
