import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router";
import {useParams, withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Checkbox, TextField, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {Check, Close} from '@mui/icons-material';
import * as actions from '../../store/actions';


const CompanyBusinessUserTable = withRouter((props) => {

  const dispatch = useDispatch()

  const { companyId } = useParams()
  const data = useSelector(state => state.company.businessUserInvitationsByCompany)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    invitationID: searchQuery.get('invitationID') || '',
    firstName: searchQuery.get('firstName') || '',
    lastName: searchQuery.get('lastName') || '',
    selfService: searchQuery.get('selfService') || '',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const invitationID = {
    title: 'Invitation ID',
    field: 'invitationID',
    editable: 'never',
    render: rowData => <div>{rowData.invitationID}</div>,
  }

  const firstName = {
    title: 'First Name',
    field: 'firstName',
    render: rowData => <div>{rowData.firstName}</div>,
  }

  const lastName = {
    title: 'Last Name',
    field: 'lastName',
    render: rowData => <div>{rowData.lastName}</div>,
  }

  const email = {
    title: 'E-Mail',
    field: 'email',
    render: rowData => <div>{rowData.email}</div>,
    editComponent: props => (
      <div>
        <TextField
          size={'small'}
          value={props.value || null}
          InputProps={{
            style: {fontSize: 13}
          }}
          onChange={e => props.onChange(e.target.value)}
        />
        {props.rowData.invitationID
          ? ''
          : <div style={{fontSize: 11, color: '#b90a0a'}}>HORUS sends invitation e-mail on behalf of the account admin</div>
        }
      </div>
    )
  }

  const phoneNumber = {
    title: 'Phone Number',
    field: 'phoneNumber',
    render: rowData => <div>{rowData.phoneNumber}</div>,
  }

  const selfService = {
    title: 'Self-Service',
    field: 'selfService',
    render: rowData => (
      <div>{rowData.selfService ? <Check style={{ color: '#5c9846' }} /> : <Close style={{ color: '#862626' }}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.selfService || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.selfService,
  }

  const costCenter = {
    title: 'Cost center',
    field: 'costCenter',
    render: rowData => <div>{rowData.costCenter}</div>,
    editComponent: props => (
      <div>
        <TextField
          size={'small'}
          value={props.value || null}
          InputProps={{
            style: {fontSize: 13}
          }}
          inputProps={{
            maxLength: 25
          }}
          onChange={e => props.onChange(e.target.value)}
        />
      </div>
    )
  }

  const deputyBooker = {
    title: 'Deputy Booker',
    field: 'deputyBooker',
    render: rowData => (
      <div>{rowData.deputyBooker ? <Check style={{ color: '#5c9846' }} /> : <Close style={{ color: '#862626' }}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.deputyBooker || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.deputyBooker,
  }

  const deputyAdmin = {
    title: 'Deputy Admin',
    field: 'deputyAdmin',
    render: rowData => (
      <div>{rowData.deputyAdmin ? <Check style={{ color: '#5c9846' }} /> : <Close style={{ color: '#862626' }}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.deputyAdmin || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.deputyAdmin,
  }

  const invitationArchivedAt = {
    title: 'Archived',
    field: 'invitationArchivedAt',
    render: rowData => (
      <div>{rowData.invitationArchivedAt ? <Check style={{color: '#5c9846' }} /> : <Close style={{ color: '#862626' }}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.invitationArchivedAt || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.invitationArchivedAt,
  }



  const columnSet = [
    invitationID,
    firstName,
    lastName,
    email,
    phoneNumber,
    selfService,
    costCenter,
    deputyBooker,
    deputyAdmin,
    invitationArchivedAt,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData => {
            return new Promise((resolve, reject) => {
              dispatch(actions.postBusinessUserInvitation({
                ...newData,
                BusinessCompanyId: companyId,
                sendEmailInvite: true,
              }))
              resolve()
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise(resolve => {
              dispatch(actions.putBusinessUserInvitation(oldData.id, {
                ...newData,
                BusinessCompanyId: companyId,
              }))
              resolve()
            })
          },

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteBusinessUserInvitation(oldData.id, companyId))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          //cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default CompanyBusinessUserTable
